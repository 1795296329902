import React from 'react';
import ipad from '../assets/images/ipad.png';
import Header from '../components/huntHeader';
import Layout from '../components/Layout';




const Clue2 = () => (
  <Layout>
    <Header /> 

    <header className="masthead2">
      <div className="container d-flex h-100 align-items-center">
        <div className="text-white-50 mx-auto text-center">
          <h1 className="mx-auto my-0 text-uppercase">Clue #2</h1>
          <br></br>
          <br></br>
          <h2 className="tracking-in-expand mx-auto mt-2 mb-5">
            {/* {config.subHeading} */}
            <br></br>
            <br></br>
            Sometimes it's sweets
            <br></br>
            quite often it's soup.
            <br></br>
            <br></br>
            Be sure to wave
            <br></br>
            when walking the loop.
            <br></br>
            <br></br>
            Grab your drink
            <br></br>
            be sure to carry it.
            <br></br>
            <br></br>
            At our place
            <br></br>
            you will find your chariot.
          </h2>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          {/* <Scroll type="id" element="about">
            <a href="#about" className="btn btn-primary">
              Hint
            </a>
          </Scroll> */}
            <a href="/hint2" className="btn btn-primary">
              Hint
            </a>  
          <br></br>
          <br></br>
          <br></br>       
        </div>
      </div>
    </header>   

    {/* <Subscribe /> */}

    {/* <SocialLinks />
    <Footer /> */}
  </Layout>
);

export default Clue2;
